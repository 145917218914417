import React, { useState } from "react"
import { graphql } from "gatsby"
import topTagsFilter from "../utils/topTagsFilter"
import { Helmet } from "react-helmet"

import VideosListingPageGridLayout from "../layouts/VideosListingPageLayout"
function VideoPage({ data, pageContext }) {
  const videos = data.videoList.nodes
  const [topTagsWithRecentPosts, sortedTags] = topTagsFilter({
    data: data.videoList.nodes,
    type: "Videos",
  })
  const [selectedTag, setSelectedTag] = useState(
    topTagsWithRecentPosts &&
      topTagsWithRecentPosts.length > 0 &&
      topTagsWithRecentPosts[0]
      ? topTagsWithRecentPosts[0].tag
      : "cassandra"
  )

  const listingItems = videos.filter((item) => item.tags.includes(selectedTag))
  return (
    <>
     <Helmet>
  <title>Cassandra Link - Videos Listing</title>
  <meta
    name="description"
    content="Explore our top videos on Cassandra - Your Source for Cassandra Resources. Welcome to our video directory on Cassandra database! Discover a collection of informative videos covering various aspects of Cassandra database management, administration, optimization, and best practices."
  />
  <meta property="og:title" content="Cassandra Link - Videos Listing" />
  <meta
    property="og:description"
    content="Explore our top videos on Cassandra - Your Source for Cassandra Resources. Welcome to our video directory on Cassandra database! Discover a collection of informative videos covering various aspects of Cassandra database management, administration, optimization, and best practices."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://cassandra.link/" />
  <meta property="og:image" content="../../images/Logo.svg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Cassandra Link - Videos Listing" />
  <meta
    name="twitter:description"
    content="Explore our top videos on Cassandra - Your Source for Cassandra Resources. Welcome to our video directory on Cassandra database! Discover a collection of informative videos covering various aspects of Cassandra database management, administration, optimization, and best practices."
  />
  <meta name="twitter:image" content="../../images/Logo.svg" />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://cassandra.link/",
      "@type": "WebPage",
      name: "Videos Listing - Cassandra Link",
      keywords: "Cassandra Link, Videos Listing",
      author: {
        "@type": "Organization",
        name: "Cassandra Link",
      },
    })}
  </script>

  {/* Other meta tags you may consider adding */}
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta name="robots" content="index, follow" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
</Helmet>

      <VideosListingPageGridLayout
        args={{
          videos,
          listingItems,
          topTagsWithRecentPosts,
          selectedTag,
          setSelectedTag,
          sortedTags,
        }}
      />
    </>
  )
}

export default VideoPage
// Removing query of null value causing error; above, uid is added to each list using UUID package
export const pageQuery = graphql`
  query VideoQuery($skip: Int, $limit: Int) {
    videoList: allCassandraLinks(
      filter: { domain_name: { regex: "/youtube/" }, content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id

        alternative_id
        title
        origin_url
        url
        wallabag_created_at

        published_by
        published_at

        language
        reading_time
        domain_name
        preview_picture
        tags
      }
    }
  }
`
