import React from "react"
import { Grid, Container } from "@mui/material"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import ListingArticleHeroSection from "../components/ArticleListingPage/ListingArticlesHeroSection"
import NewsLetterCard from "../components/ArticleSinglePage/Cards/NewsLetterCard"
import TrainingAdComponent from "../components/ArticleSinglePage/Cards/TrainingAdComponent"
import ExploreFurther from "../layouts/ExploreFurtherLayout"
import ArticlesFilters from "../components/ArticleListingPage/ArticlesFilters"
import ListArticlesLayout from "../layouts/ListArticlesLayout"
import ListingRelatedArticlesLayout from "./ListingRelatedArticlesLayout"

function VideosListingPageGridLayout({
  args: { videos, listingItems, selectedTag, setSelectedTag, sortedTags },
}) {
  return (
    <Layout>
      <Grid container>
        <Grid item xs={12}>
          <ListingArticleHeroSection
            hasVideoContent={true}
            title={"videos"}
            data={videos.slice(0, 3)}
          />
        </Grid>
        <Container sx={{ marginTop: { xs: 8, sm: 0 } }} maxWidth="xl">
          <Grid sx={{ marginY: 5 }} item xs={12}>
            <ArticlesFilters
              args={{
                fileItems: sortedTags,
                selectedTag: selectedTag,
                setSelectedTag: setSelectedTag,
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <ListArticlesLayout
                data={listingItems}
                hasSharingContent={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TrainingAdComponent buttonLink={"/training-cassandra"} />
                </Grid>
                <Grid item xs={12}>
                  <ListingRelatedArticlesLayout
                    hasVideoLink={true}
                    data={videos.slice(7, 10)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NewsLetterCard />
                </Grid>
                <Grid item xs={12}>
                  <ListingRelatedArticlesLayout
                    hasVideoLink={true}
                    data={videos.slice(10, 13)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ marginY: 3 }} item xs={12}>
            <ExploreFurther
              args={{
                data: videos.slice(0, 12),
                isListingPage: true,
              }}
            />
          </Grid>
        </Container>
      </Grid>
    </Layout>
  )
}

export default VideosListingPageGridLayout
